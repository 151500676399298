<template>
    <default-layout>
      <div class="job-details">
        <h1 class="mobile-title">Vacantes</h1>
        <div class="jobs">
          <div class="jobs__info">
            <div class="jobs__info__header">
              <div class="title">
                <h1 v-if="jobInfo">{{jobInfo.title}}</h1>
              </div>
              <div class="apply-button">
                <button @click="apply">Aplicar</button>
              </div>
            </div>
            <div class="jobs__info__content">
              <h4>Descripción</h4>
              <span v-for="(job, index) in setLineBreak(jobInfo.description)" :key="index">{{job}}<br></span>
            </div>
            <div class="jobs__info__content">
              <h4>Requisitos</h4>
              <span v-for="(job, index) in setLineBreak(jobInfo.requirements)" :key="index">{{job}}<br></span>
            </div>
            <div class="jobs__info__content">
              <h4>Tipo de jornada</h4>
              <span v-for="(job, index) in setLineBreak(jobInfo.type)" :key="index" class="job-type">
                <span v-for="(type, index) in setStyle(job)" :key="index">
                  <span class="job-type">{{type}}</span>
                  <br>
                  <span v-for="(jobDetail, index) in getJobTypeDetails(type)" :key="index">
                  {{ jobDetail }} <br>
                  </span>
                  <br v-if="setStyle(job).length > 1 && index < setStyle(job).length - 1">
                </span>

              </span>
            </div>
            <div class="jobs__info__content">
              <h4>Salario</h4>
              <span>${{jobInfo.minSalary}} - ${{jobInfo.maxSalary}}<br></span>
            </div>
            <div class="jobs__info__content">
              <h4>Tipo de contrato</h4>
              <span>Temporal<br></span>
            </div>
            <div class="jobs__info__content">
              <h4>Prestaciones</h4>
              <span v-for="(job, index) in setLineBreak(jobInfo.benefits)" :key="index">{{job}}<br></span>
            </div>
            <div class="jobs__info__content">
              <h4>Compensaciones</h4>
              <span v-for="(job, index) in setStyle(jobInfo.compensations)" :key="index">{{job}}<br></span>
            </div>
          </div>
          <div class="jobs__image">
            <img class="about-job" v-if="noImage == false" :src="jobImage[jobInfo.id]" alt="">
            <img class="join-us" v-else src="@/assets/join-us.jpg" alt="">
          </div>
        </div> 
      </div>
    </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout';

const JOB_TYPE_DETAILS = [
  {id: 'Turno Completo', label: '8 Horas Diarias*6 Dias a la Semana*Posibilidad de Tiempo Extra'},
  {id: 'Medio Turno', label: 'Horario Flexible de Lunes a Viernes*8 Horas Diarias Sábados y Domingos*Posibilidad de Tiempo Extra'},
  {id: 'Fin de Semana', label: '8 Horas Diarias Sábados y Domingos*Posibilidad de Tiempo Extra'},
  {id: 'Turno de Relevo', label: '4 a 6 Horas Diarias*Horario Flexible*Este puesto esta diseñado para personas que ya tienen empleo en otra empresa y tienen disponibilidad de tiempo para un segundo empleo. Nosotros nos adaptamos a tu horario laboral preexistente.'},
]

export default {
  components: {DefaultLayout},
  mounted() {
      this.getJobInfo(this.$route.params.jobInfo)
  },
  data() {
    return {
      jobInfo: {},
      jobImage: {},
      noImage: true,
    }
  },
  methods: {
    async getJobInfo(jobInfo) {
      if(this.$route.params.jobInfo) {
        this.jobInfo = jobInfo
      } else {
        this.jobInfo = await this.$store.dispatch('getJobPostsForWebById', this.$route.params.id)
      }
      this.getJobImage(this.jobInfo.id)
    },
    setStyle(text) {
      if(text) {
        return text.split(',')
      }
    },
    setLineBreak(text) {
      if(text) {
        return text.split('*')
      }
    },
    getJobTypeDetails(job) {
      job = JOB_TYPE_DETAILS.filter(detail => detail.id == job)[0].label;
      return this.setLineBreak(job);
    },
    async getJobImage(postId) {
      let src = await this.$store.dispatch('getJobImage', postId);
      
      if(src) {
        this.$set(this.jobImage, postId, src ? 'data:image/png;base64,'.concat(src) : null);
        this.noImage = false;
      } else {
        this.noImage = true;
      }
    },
    apply() {
      this.$router.push({
        name: 'job-application',
        params: {
          id: this.$route.params.id
        }
      });
    }
  }
}
</script>
  
<style lang="scss">
.mobile-title {
  margin-top: 24px;
  display: none;
}
.jobs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 48px 0;
  gap: 48px;
  .apply-button {
    button {
      cursor: pointer;
    }
  }
  &__info {
    width: auto;
    background: #FFFFFF;
    &__header {
      margin: 24px;
      display: grid;
      grid-gap: 16px;
      .title {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
      span {
        color: $orange-color;
      }
      h1 {
        font-family: $font-secondary;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: $dark-brown-color;
      }
    }
    &__content {
      margin: 24px;
      h4 {
        line-height: 22px;
        letter-spacing: 0.05em;
        margin-bottom: 2px;
      }
      span {
        color: #786F66;
        letter-spacing: 0.05em;
        line-height: 24px;
      }
      .job-type {
        color: #1E0F00;
      }
    }
  }
  &__image {
    .about-job {
      width: 100%;
      height: auto;
      max-height: 400px;
      object-fit: cover;
    }
    .join-us {
      width: 100%;
      max-height: 336px;
      object-fit: cover;
    }
  }
}
@include respond-to("medium") {
  .jobs {
    display: grid;
    grid-template-columns: 1fr;
    &__info {
      &__header {
        h1 {
          width: auto;
        }
      }
    }
    &__image {
      .about-job {
        width: 100%;
        max-height: 600px;
      }
      .join-us {
        width: 100%;
        max-height: 100%;
        object-fit: cover;
      }
    }
  }
}
@include respond-to("medium and up") {
  .jobs {
    &__info {
      &__header {
        grid-template-columns: 1fr 200px;
        button {
          width: 100%;
        }
      }
    }
  }
}
@include respond-to("small and down") {
  .job-details {
    margin-top: 24px;
  }
  
  .mobile-title {
    display: inline;
  }
  .jobs {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 1fr;
    &__info {
      &__header {
        display: grid;
        grid-template-columns: 1fr;
        button {
          left: 0;
        }
      }
    }
    &__image {
      object-fit: cover;
    }
  }
}
</style>